import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k222.jpg'
import image_product_mobile from '../../../images/product/k222_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K222Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true}
		  		customClass='product-title'
		  		activePath='product'
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-222 Interior Render">
		  	<ProductPage
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-222 Interior Render"
		  		productID={"78"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"cipn",
		  					"name":substratelabels['cipn']
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K222Page
